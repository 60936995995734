import Vue from 'vue'
import App from './App.vue'
import fb from 'firebase';
import VSwipe from 'vswipe';

import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false;
Vue.use( VSwipe )

new Vue( {
	vuetify,
	render: h => h( App ),
	router,

	created() {

		var firebaseConfig;

		//   if (process.env.NODE_ENV !== "production")
		// firebaseConfig = {
		//     apiKey: "AIzaSyBlMzKt1cabb8iYYSIpZL8Y-qLnxK8Kpt4",
		//     authDomain: "yta1-b98f1.firebaseapp.com",
		//     databaseURL: "https://yta1-b98f1.firebaseio.com",
		//     projectId: "yta1-b98f1",
		//     storageBucket: "yta1-b98f1.appspot.com",
		//     messagingSenderId: "608048095515",
		//     appId: "1:608048095515:web:1369f1d524a6e2edaff3be"
		// };
		//   else
		// firebaseConfig = {
		//   apiKey: "AIzaSyBi32Qv0i3Kjo0C5I2Fey_wJEYOUhdH5vE",
		//   authDomain: "analytics-a9758.firebaseapp.com",
		//   databaseURL: "https://analytics-a9758.firebaseio.com",
		//   projectId: "analytics-a9758",
		//   storageBucket: "analytics-a9758.appspot.com",
		//   messagingSenderId: "438928198143",
		//   appId: "1:438928198143:web:9d523f8782d4789659ffca"
		// }


		firebaseConfig = {
			apiKey: "AIzaSyAOQQdIdP2IoFLo4gXyv1FF9NkT2NBewCA",
			authDomain: "analytics-8e17b.firebaseapp.com",
			projectId: "analytics-8e17b",
			storageBucket: "analytics-8e17b.appspot.com",
			messagingSenderId: "1003448722932",
			appId: "1:1003448722932:web:e6c8aee0abeecf565c45a1"
		}

		// Initialize Firebase
		fb.initializeApp( firebaseConfig );
	}
} ).$mount( '#app' )
