<template>
    <v-app>
        <main>
            <v-container class="loginOverlay" fill-height fluid>
                <v-layout align-center flex justify-center style="margin-top: 20px;">
                    <v-flex sm4 xs12>
                        <v-toolbar class="elevation-2"
                                   color="#FFFbbb"
                                   style="position: relative; z-index: 10; border: 1px solid #fffbbb; border-top-left-radius: 12px; border-top-right-radius: 12px;">
                            <v-toolbar-title>Авторизация в A4 Monitor</v-toolbar-title>
                        </v-toolbar>
                        <v-card class="elevation-4"
                                style="border-bottom-left-radius: 12px; border-bottom-right-radius: 12px;">
                            <v-card-text class="pt-4">
                                <div>
                                    <v-form ref="form" v-model="valid">
                                        <v-text-field v-model="email"
                                                      :placeholder="!autofilled ? ' ' : ''"
                                                      :rules="emailRules"
                                                      autofocus
                                                      label="Введите ваш Email"
                                                      required
                                        ></v-text-field>
                                        <v-text-field
                                                v-model="password"
                                                :placeholder="!autofilled ? ' ' : ''"
                                                :rules="passwordRules"
                                                :type="e1 ? 'password' : 'text'"
                                                counter
                                                label="Введите ваш пароль"
                                                min="8"
                                                required
                                                @keypress.enter="submit"
                                        ></v-text-field>
                                        <v-layout justify-space-between>
                                            <v-checkbox v-model="showSiteOnTV" class="mt-3" color="gray"
                                                        dense label="Отображать на TV">
                                            </v-checkbox>
                                            <v-spacer></v-spacer>
<!--                                            <v-checkbox v-model="showSiteOnMobile" class="mt-3" color="gray"-->
<!--                                                        dense label="Mobile">-->
<!--                                            </v-checkbox>-->
<!--                                            <v-spacer></v-spacer>-->
                                            <v-btn :class="{'primary':valid}" :disabled="!valid" :style="{'background-color': (valid ? '#fffbbb': 'lightgray') + ' !important' , 'color': (valid ? 'black': 'lightgray')}"
                                                   class=" mt-4 pl-4 pr-4 "
                                                   @click="submit">Login
                                            </v-btn>
                                            <!--                                            <a href="">Forgot Password</a>-->
                                        </v-layout>
                                    </v-form>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>

        </main>
    </v-app>
</template>

<script>

import fb from 'firebase';
import Cookies from 'js-cookie';

export default {
    name: "Home",
    data() {
        return {
            showSiteOnTV: true,
            valid: false,
            e1: true,
            password: '',
            passwordRules: [
                (v) => !!v || 'Пароль обязателеное поле ввода',
            ],
            email: '',
            emailRules: [
                (v) => !!v || 'E-mail обязателеное поле ввода',
                (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
            autofilled: false,
            showSiteOnMobile: false,
        }
    },
    mounted() {
        if (Cookies.get('showSiteOnTV') == 1) {
            this.showSiteOnTV = true;

        } else {
            this.showSiteOnTV = false;
            Cookies.set('showSiteOnTV', 0);
        }

        if (location.host !== 'monitor.a4.by') this.showSiteOnMobile = true;
        else this.showSiteOnMobile = false;
    },
    watch: {
        email() {
            this.autofilled = true;
        },
        password() {
            this.autofilled = true;
        }
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {

                (async () => {

                    try {
                        await fb.auth().signInWithEmailAndPassword(this.email, this.password);
                        // console.log('u', JSON.stringify(u, undefined, 4));
                    } catch (error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        if (errorCode === 'auth/wrong-password') {
                            alert('Пароль неверный.');
                        } else {
                            alert(errorMessage);
                        }
                        console.log(error);
                        return this.clear();
                    }


                    let ownerDoc = await fb.firestore().collection('rules').doc(this.email).get();
                    console.log(ownerDoc);

                    await Cookies.set('user', this.email, {expires: 30});
                    await Cookies.set('showSiteOnTV', this.showSiteOnTV ? 1 : 0);
                    await Cookies.set('pp', JSON.stringify(this.password.split('').map(el => el.charCodeAt() + 1)), {expires: 30});

                    // if (this.showSiteOnMobile)
                    //     await this.$router.push({name: 'MonitorMobile',})
                    // else
                        await this.$router.push({name: 'Monitor',})


                })()

            }
        },
        clear() {
            this.$refs.form.reset()
        }
    },
}
</script>


